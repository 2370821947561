/* eslint-disable camelcase */
import Link from 'next/link';

import { StyledBaseCTA } from '@/styles/index';
import { Icon } from 'react-icons-kit';
import { userPlus } from 'react-icons-kit/fa/userPlus';
// import { userO } from 'react-icons-kit/fa/userO';
import { ic_add } from 'react-icons-kit/md/ic_add';

const BaseCTA = () => (
  <StyledBaseCTA>
    <div className="cta-lead">Create polls, no tears.</div>
    <div className="cta-actions">
      <Link href="/signup">
        <a>
          <button type="button" className="btn-white">
            <Icon size={28} icon={userPlus} /> Sign Up
          </button>
        </a>
      </Link>
      {/* <Link href="/premium">
        <a>
          <button type="button" className="link">
            Pricing
          </button>
        </a>
      </Link> */}
      <Link href="/create">
        <a>
          <button type="button" className="btn-orange">
            <Icon size={28} icon={ic_add} /> New Poll
          </button>
        </a>
      </Link>
    </div>
  </StyledBaseCTA>
);

export default BaseCTA;
